import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Input,
} from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import Tabs, {
  TabPane,
} from "../../../modUtils/components/componentsLibrary/tabs/Tabs";
import { get_sale_detail_by_group } from "../../slices/saleSlice";
import { dateFormat } from "../../../modUtils/functions/dateFormat";
import { amountFormatWithCurrencySymbol } from "../../../modUtils/functions/numberFormat";
import SupportTransactionDetails from "../../../modWalletLocal/components/SupportTransactionDetails";
import PaiementCBInfos from "../../../modBanking/components/PaiementCBInfos";
import { eCdbsPaymentType } from "../../../../commun_global/enums";

import "./SaleDetailsModal.scss";

export default function SaleDetailsModal({ closeMenu, saleId, closeModal }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { saleDetails, loading } = useSelector((state) => state.sale);
  const { groupId, loadingGroup } = useSelector((state) => state.manager);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const { TabPane } = Tabs;

  useEffect(() => {
    const fetchData = async () => {
      setLoadingDetails(true);
      await dispatch(get_sale_detail_by_group({ groupId, saleId }));
      closeMenu && closeMenu();

      loadingDetails && setModalVisible(true);
      setLoadingDetails(false);
    };

    fetchData();
  }, []);

  const onCloseModal = () => {
    closeModal(); // Appeler la fonction de fermeture passée en prop
  };

  //renderPaymentTabs fonction qui traite l'affichage des tabs dans le cas ou paiement cb ou paiement par badge
  const renderPaymentTabs = () => {
    const tabsToRender = saleDetails?.payments
      ?.map((payment, index) => {
        if (
          payment?.type_payment == 3 ||
          payment?.type_payment == 2 ||
          payment?.type_payment == 11
        ) {
          console.log(saleDetails);
          return (
            <TabPane
              label={
                payment?.type_payment == 2
                  ? "Informations paiement Badge"
                  : "Informations paiement CB"
              }
              tabKey={
                payment?.type_payment == 2
                  ? "badge-tab-" + index
                  : "cb-tab-" + index
              }
              key={
                payment?.type_payment == 2
                  ? "badge-tab-" + index
                  : "cb-tab-" + index
              }
            >
              {payment?.type_payment == 2 ? (
                <SupportTransactionDetails
                  saleId={saleDetails?.id}
                  supportId={saleDetails?.identification?.element_id}
                />
              ) : (
                <PaiementCBInfos
                  saleId={saleDetails?.id}
                  saleDate={saleDetails?.date_probable}
                />
              )}
            </TabPane>
          );
        }

        // Si payment.type_payment n'est ni 2  ni 3 ni 11, retournez null.
        return null;
      })
      .filter(Boolean);

    // Vérifiez s'il y a des onglets à rendre
    if (tabsToRender?.length > 0) {
      return (
        <Tabs
          //tabHeight={280}
          style={{ tabContent: { backgroundColor: "#ecf4fe" } }}
        >
          {tabsToRender}
        </Tabs>
      );
    }

    // Si aucun onglet n'est à rendre, retournez null
    return null;
  };
  const retailRows = saleDetails?.retails?.map((retail) => (
    <tr key={retail?.id}>
      <td>{retail?.label_local}</td>
      <td>* {retail?.quantity}</td>
      <td>{amountFormatWithCurrencySymbol(retail?.price_include_vat)}</td>
    </tr>
  ));

  const paymentRows = saleDetails?.payments?.map((payment) => (
    <tr key={payment?.id}>
      <td>{t(eCdbsPaymentType[payment?.type_payment])}</td>
      <td>
        {payment?.count > 1 && payment?.count + " * "}
        {amountFormatWithCurrencySymbol(payment?.unit)}
      </td>
      <td>{amountFormatWithCurrencySymbol(payment.amount)}</td>
    </tr>
  ));

  // Calcul du nombre de lignes
  const retailRowCount = retailRows ? retailRows.length : 0;
  const paymentRowCount = paymentRows ? paymentRows.length : 0;

  // Comparaison et ajout de lignes vides si nécessaire
  const maxRowCount = Math.max(retailRowCount, paymentRowCount);

  // Utilisez le tableau avec le nombre de lignes le plus petit pour l'ajout de lignes vides
  const smallerRowCountArray =
    retailRowCount <= paymentRowCount ? retailRows : paymentRows;

  while (smallerRowCountArray?.length < maxRowCount) {
    smallerRowCountArray.push(
      <tr key={`emptyRow${smallerRowCountArray.length + 1}`}>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  }
  return (
    <Modal
      title="DETAILS DE LA TRANSACTION"
      isOpen={modalVisible}
      onClose={onCloseModal}
      width="70%"
      height="90%"
    >
      {!loadingDetails && (
        <>
          <div className="transactionDetailsForm info-container">
            <div className="grid lg:grid-cols-3 gap-4">
              <div>
                <span>Vente ID</span>

                <Input
                  id="id"
                  value={saleDetails?.id}
                  className="w-full"
                  size={30}
                  disabled
                />
              </div>
              <div>
                <span>Date de la transaction</span>

                <Input
                  id="date_probable"
                  value={dateFormat(saleDetails?.date_probable)}
                  size={30}
                  className="w-full"
                  disabled
                />
              </div>
              <div>
                <span>Total</span>

                <Input
                  id="amount_paid_vat"
                  value={amountFormatWithCurrencySymbol(
                    saleDetails?.price_total_selected
                  )}
                  size={30}
                  disabled
                  className="w-full"
                />
              </div>
              <div>
                <span> Nom du site</span>

                <Input
                  id="site"
                  value={saleDetails?.site}
                  className="w-full"
                  size={30}
                  disabled
                />
              </div>
              <div>
                <span>Nom de l'équipement</span>

                <Input
                  id="equipmentName"
                  value={saleDetails?.equipment?.name}
                  size={30}
                  className="w-full"
                  disabled
                />
              </div>

              <div>
                <span>Board/version</span>

                <Input
                  id="boardVersion"
                  value={
                    saleDetails?.equipment?.hardware_id &&
                    saleDetails?.equipment?.hardware_version &&
                    `${saleDetails?.equipment?.hardware_id} / ${saleDetails?.equipment?.hardware_version}`
                  }
                  size={30}
                  disabled
                  className="w-full"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-7 gap-4 sale-details-tables">
            <div className="col-span-1 md:col-span-3">
              <table className="w-full">
                <thead>
                  <tr>
                    <th>Produits achetés</th>
                    <th>Quantité</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>{retailRows}</tbody>
              </table>
            </div>

            <div className="col-span-1 flex justify-center items-center">
              {saleDetails.has_error_balance ? (
                <img
                  src={process.env.PUBLIC_URL + "/img/unequalIcon.png"}
                  alt="img"
                />
              ) : (
                <img
                  src={process.env.PUBLIC_URL + "/img/equalIcon.png"}
                  alt="img"
                />
              )}
            </div>

            <div className="col-span-1 md:col-span-3">
              <table className="w-full">
                <thead>
                  <tr>
                    <th>Moyens de paiement</th>
                    <th>Détail</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>{paymentRows}</tbody>
              </table>
            </div>
          </div>

          <div>{renderPaymentTabs()}</div>
        </>
      )}
    </Modal>
  );
}
