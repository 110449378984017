import commonTranslation from "ck_commun/src/app/modUtils/locale/commonTranslation";
import translationModCumulativeEN from 'ck_commun/src/app/modCumulative/locale/en.json';
import translationModCumulativeFR from 'ck_commun/src/app/modCumulative/locale/fr.json';
import translationModEntityEN from 'ck_commun/src/app/modEntity/locale/en.json';
import translationModEntityFR from 'ck_commun/src/app/modEntity/locale/fr.json';
import translationModSharedEquipmentEN from 'ck_commun/src/app/modSharedEquipment/locale/en.json';
import translationModSharedEquipmentFR from 'ck_commun/src/app/modSharedEquipment/locale/fr.json';
import translationModStatementEN from 'ck_commun/src/app/modStatement/locale/en.json';
import translationModStatementFR from 'ck_commun/src/app/modStatement/locale/fr.json';
import translationModWalletLocalEN from 'ck_commun/src/app/modWalletLocal/locale/en.json';
import translationModWalletLocalFR from 'ck_commun/src/app/modWalletLocal/locale/fr.json';

// ajout des traductions des modules spécifiques a l'application, 
// les traductions communes sont dans le fichier commonTranslation.js et comprennent les modules
// toujours présents dans les projets CK
const translation = {
    en: {
        ...commonTranslation.en,
        ...translationModCumulativeEN,
        ...translationModEntityEN,
        ...translationModSharedEquipmentEN,
        ...translationModStatementEN,
        ...translationModWalletLocalEN
    },
    fr: {
        ...commonTranslation.fr,
        ...translationModCumulativeFR,
        ...translationModEntityFR,
        ...translationModSharedEquipmentFR,
        ...translationModStatementFR,
        ...translationModWalletLocalFR
    }
};
  
export default translation;