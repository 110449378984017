import React, { useState, useEffect, useRef } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { get_entity_paginated_selected } from "../../slices/entitySlice";
import { ReactComponent as EntityCompanyIcon } from "../../../modEntity/assets/images/company-building.svg";
import { ReactComponent as EntityGroupIcon } from "../../../modEntity/assets/images/users-three-bg-white.svg";

export default function SelectEntityParent({ entity, setSelected, entityDetail }) {
    const defaultLimit = 25;
    const { Option } = Select;
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const [page, setPage] = useState(1);
    const [selectedValue, setSelectedValue] = useState();
    const [groupOptions, setGroupOptions] = useState([]);
    const [searchTimeout, setSearchTimeout] = useState(null);
    const { groupId } = useSelector((state) => state.manager);
    const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(true);
    const { entities, entitiesCount } = useSelector((state) => state.entity);
    
    // TODO CH il doit manquer des element pour fetch des options
    const [loading, setLoading] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState();

    //handleSelect appelée quand on sélectionne
    const handleSelect = (newValue) => {
        setSelectedValue(newValue);
        setSelected(newValue)
        // ici set la donner pour le formulaire
        setPage(1); // Reset page when selected group changes
    };

    //handleSearch fonction appelée quand on cherche un groupe, càd la valeur de searchInputValue change
    const handleSearch = (newValue) => {
        setSearchInputValue(newValue);
        setPage(1);
        if (newValue.length >= 3 && !(newValue.length === 3 && newValue[2] === " ")) {
        //faire l'appel à l'api que quand searchInputValue contient 3 caractères ou plus, et le 3ème caractère n'est pas vide
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        setSearchTimeout(
            setTimeout(() => {
                fetchData(newValue, true, true);
            }, 500) //searchTimeOut utilisé pour ne pas appeler l'api à chaque fois qu'on écrit un caractère de plus, limiter les appels inutiles par un temps d'attente de 500ms
        );
        }
    };

    //handlePopupScroll fonction appelée quand on scrolle dans le dropdown du select
    const handlePopupScroll = (event) => {
        const { target } = event;
        if (!loading
            && target.scrollTop + target.offsetHeight === target.scrollHeight
            && hasMoreDataToLoad) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    // fonction ajoutée pour recharger la liste des groupes (pour ne pas afficher la dernière liste recherchée)
    const handleDropdownVisibleChange = (visible) => {
        setPage(1); // Reset the page number
        setSearchInputValue();
        if (visible) {
            if (dropdownRef.current) {
                dropdownRef.current.scrollTop = 0; // Scroll dropdown to top
            }
            // ici modifier le get
            // fetchData(null, true, true); // Fetch, clear search value, clear previous data, and reset page
        }
    };

    //récupérer la liste des groupes et ensuite stocke les données dans groupOptions sous forme {value: groupId, label: groupName}
    const fetchData = async (searchValue = null, clearData = false, resetPage = false) => {
            const requestParams = {
                groupId,
                limit: defaultLimit,
                offset: resetPage ? 0 : (page - 1) * defaultLimit
            };

            if (searchValue !== null) {
                //ne pas passer le filtre par name que quand il est passé en props de fetchData
                requestParams.name = searchValue;
            }

            let options = [];

            await dispatch(get_entity_paginated_selected(requestParams)).then((response) => {
                options = response.payload.results;
                const newData = options?.map((option) => ({
                    value: option.id,
                    name: option.name,
                    first_name: option.first_name
                }));

                // Mise à jour de l'état HasMoreDataToLoad en fonction de la valeur de "next" dans la réponse de l'API
                setHasMoreDataToLoad(response?.payload?.next !== null);
                if (clearData) {
                    setGroupOptions(newData);
                } else if ((searchValue && page === 1) || page === 1) {
                    setGroupOptions(newData);
                } else {
                    setGroupOptions((prevData) => [...prevData, ...newData]);
                }
            });
            return options;
    };

    useEffect(() => {
        const newData = [
            { value: null, name: "aucun", entity_type: null }, // Valeur par défaut ajoutée au début
            ...entities
                .filter((entityItem) => 
                    entityItem?.id !== entity?.id && (entityItem.entity_type === "EntityCompany" || entityItem.entity_type === "EntityGroup")
                )
                .map((entityItem) => {
                    // Si l'entité a des childs, les inclure également
                    const children = entityItem?.childs
                        ?.filter((child) => 
                            child?.id !== entity?.id && child?.entity_type === "EntityGroup"
                        )
                        .map((child) => ({
                            value: child?.id,
                            name: child?.name,
                            first_name: child?.first_name,
                            entity_type: child?.entity_type
                        })) || [];
    
                    // Inclure l'entité principale avec ses enfants s'il y en a
                    return [
                        {
                            value: entityItem?.id,
                            name: entityItem?.name,
                            first_name: entityItem?.first_name,
                            entity_type: entityItem?.entity_type
                        },
                        ...children, // Ajouter les childs dans la même liste
                    ];
                })
                .flat() // Aplatir pour éviter une structure imbriquée
        ];
        setGroupOptions(newData);
    }, [entities, entity]);

    useEffect(() => {
        if (entityDetail?.entity_customer?.parent?.id) {
            setSelectedValue(entityDetail?.entity_customer?.parent?.id);
        }else if (entityDetail?.entity_group?.parent?.id) {
            setSelectedValue(entityDetail?.entity_group?.parent?.id);
        }else {
            setSelectedValue(null);
        }
    }, [entityDetail, entities, entity]);

    const getIcon = (entityType) => {
        let icon;

        if (entityType === "EntityCompany") {
            icon = <EntityCompanyIcon className="stroke-primary-color w-5 h-4"/>
        }else if (entityType === "EntityGroup") {
            icon = <EntityGroupIcon className="stroke-primary-color w-5 h-4"/>
        }

        return icon;
    }

    return (
        <div className="select-group-container w-[300px] mb-8 text-left">
            <Select
                showSearch
                value={selectedValue}
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={handleSearch}
                onPopupScroll={handlePopupScroll}
                suffixIcon={
                    entitiesCount > 1 && (
                        <img
                            src={process.env.PUBLIC_URL + "/img/design/zoom.svg"}
                            alt="zoom"
                        />
                    )
                }
                onChange={handleSelect}
                notFoundContent={"Aucun groupe ne correspond à votre recherche"} // TODO CH  modifier et traduire
                listHeight={200}
                className={`select-customize-input ${
                    entitiesCount > 1 || !loading ? "" : "pointer-events-none"
                }`} //désactiver le fonctionnement du select quand on a un seul element
                onDropdownVisibleChange={handleDropdownVisibleChange}
                dropdownRender={(menu) => <div ref={dropdownRef}>{menu}</div>}
            >
                {groupOptions?.map((option) => (
                    <Option key={option.value} value={option.value}>
                        {getIcon(option.entity_type)} {option?.name}
                    </Option>
                ))}
            </Select>
        </div>
    );
};
