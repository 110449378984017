import React, { useState, useEffect } from "react";
import Layout from "ck_commun/src/app/modUtils/components/componentsLibrary/layout/Layout";
import Sider from "ck_commun/src/app/modUtils/components/componentsLibrary/layout/Sider";
import Content from "ck_commun/src/app/modUtils/components/componentsLibrary/layout/Content";
import RightSideBar from "ck_commun/src/app/modUtils/components/rightSideBar/RightSideBar";
import LeftSiderBar from "ck_commun/src/app/modUtils/components/leftSideBar/LeftSideBar";

import { Outlet } from "react-router-dom";
import BurgerButton from "ck_commun/src/app/modUtils/components/burgerMenu/BurgerButton";
import BurgerMenu from "ck_commun/src/app/modUtils/components/burgerMenu/BurgerMenu";
import menuGenerate from "../../../utils/menuGenerate";
import { appLogo, appShortName } from "../../../utils/constants";



export default function MainLayout() {
    const [collapsedLeft, setCollapsedLeft] = useState(false);
    const [collapsedRight, setCollapsedRight] = useState(false);
    const [collapsedBurger, setCollapsedBurger] = useState(false);
    const menuItems = menuGenerate(); 

    const toggleMobileMenu = () => {
        setCollapsedBurger(!collapsedBurger)
    };

    return (
        <Layout>
            <BurgerButton onClick={toggleMobileMenu} />

            <div className={`${collapsedBurger ? "block md:hidden" : "hidden"
                } absolute z-[100]
            `}>
            <BurgerMenu collapsed={false} menuItems={menuItems} toggleMobileMenu={toggleMobileMenu}/>
            </div>


            <div className="hidden md:block">
                <Sider position="left" collapsed={collapsedLeft} setCollapsed={setCollapsedLeft}>
                    <LeftSiderBar collapsed={collapsedLeft} menuItems={menuItems} appName={appShortName} applogo={appLogo} />
                </Sider> 
            </div>
            

            <Content>
                <Outlet/>
            </Content>


            <div className="hidden md:block">
                <Sider position="right" collapsed={collapsedRight} setCollapsed={setCollapsedRight} collapseWidth="50px">
                    <RightSideBar collapsed={collapsedRight} />
                </Sider>
            </div>
            
        </Layout>
    );
};
