import React, { useState } from "react";

/*contents*/
import Accounts from "./contents/Accounts";
import CustomerLoyalty from "./contents/CustomerLoyalty";
import Informations from "./contents/Informations";
import Profiles from "./contents/Profiles";
import Supports from "./contents/Supports";
import Histories from "./contents/Histories";
import ButtonCard from "../ButtonCard";
import BuildingPage from "ck_commun/src/app/modUtils/components/errorPage/BuildingPage";

export default function Clients() {
  return (
    <>
      <BuildingPage />
    </>
  );
}
