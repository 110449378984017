import React, { useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import PropTypes from "prop-types";
import "react-calendar/dist/Calendar.css";
import { FaCalendarAlt } from "react-icons/fa";

const DatePicker = ({
  format,
  selectedDate,
  onDateChange,
  label,
  placeholder,
  color = "primary",
}) => {
  // State pour gérer l'affichage ou non du calendrier
  const [showCalendar, setShowCalendar] = useState(false);

  // Fonction pour basculer l'affichage du calendrier
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  // Fonction pour gérer le changement de date
  const handleDateChange = (date) => {
    // Appeler la fonction de changement de date fournie par le composant parent
    onDateChange(formatSelectedDate(date));
    // Masquer le calendrier après la sélection d'une date
    toggleCalendar();
  };

  // Fonction pour formater la date sélectionnée selon le format spécifié
  const formatSelectedDate = (date) => {
    return date ? moment(date, format).format(format) : "";
  };

  const parsedDate = moment(selectedDate, format);
  // Format the parsed date into "MM/DD/YYYY" format (format par défaut de Calendar)
  const formattedDate = parsedDate.isValid()
    ? parsedDate.format("MM/DD/YYYY")
    : null;

  const colorVariants = {
    primary: "border-primary-color-600 text-primary-color-600",
    secondary: "border-secondary-color-600 text-secondary-color-600",
    thirty: "border-thirty-color-600 text-thirty-color-600",
    neutral: "border-neutral-color-600 text-neutral-color-600",
  };

  return (
    <div className="date-picker relative">
      <div
        className={`text-left font-black text-sm h-[24px] ${colorVariants[color]}`}
      >
        {label}
      </div>
      <div
        className={`
            flex flex-row justify-between items-center p-2.5 w-44  h-10
            border border-solid rounded-md cursor-pointer
            ${colorVariants[color]}
        `}
        onClick={toggleCalendar}
      >
        <input
          type="text"
          value={formatSelectedDate(selectedDate)}
          placeholder={placeholder}
          className="!w-full active:!bg-transparent active:!border-none"
          style={{ all: "unset" }}
        />
        <FaCalendarAlt color="#338EF5" fontSize={"16px"} />
      </div>
      {/* Affichage conditionnel du calendrier en fonction de l'état showCalendar */}
      {showCalendar && (
        <div
          className={`absolute top-16 left-0 w-[250px] shadow-md z-[999]
        bg-white border border-solid border-gray-300 rounded-md p-2`}
        >
          <Calendar
            value={formattedDate}
            onChange={(date) => handleDateChange(date)}
          />
        </div>
      )}
    </div>
  );
};

DatePicker.defaultProps = {
  placeholder: "Select a date",
  format: "DD/MM/YYYY HH:mm",
};

DatePicker.propTypes = {
  format: PropTypes.string,
};

export default DatePicker;
