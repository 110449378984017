import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { eEquipmentType } from "ck_commun/src/commun_global/enums.js";
import { get_activity_cumulative, get_statistic_by_payment_types_distribution_list, get_statistic_by_payment_types_distribution_by_period_list, get_statistic_by_payment_types_global_by_period_list, get_statistic_global_by_day_list } from "ck_commun/src/app/modCumulative/slices/cumulativeSlice";
import { Select } from "ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary";
import SubmitButton from "ck_commun/src/app/modUtils/components/buttons/submitButton/SubmitButton";
import FilterByEquipmentType from "ck_commun/src/app/modUtils/components/filters/FilterByEquipmentType";
import FilterBySearchSite from "ck_commun/src/app/modManager/components/filterBySearchSite/FilterBySearchSite";
import FilterByDate from "ck_commun/src/app/modUtils/components/filters/FilterByDate";
import StatisticsGlobal from "ck_commun/src/app/modCumulative/components/StasticsGlobal";
import StatisticsByPaymentType from "ck_commun/src/app/modCumulative/components/statisticsByPaymentType/StatisticsByPaymentType";
import StatisticsByEquipmentType from "ck_commun/src/app/modCumulative/components/StatisticsByEquipmentType";
import StatisticsByService from "ck_commun/src/app/modCumulative/components/StatisticsByService";

const filterStatisticByCategoryOptions = [
  { label: "Par site", value: "site" },
  { label: "Par moyen de paiement", value: "paymentType" },
  { label: "Par type d'équipement ", value: "equipmentType" },
];

export default function StatisticsPage() {
  const dispatch = useDispatch();
  const { groupId, selectedGroup } = useSelector((state) => state.manager);
  const { activityCumuleByGroupId, statisticByPaymentTypesDistribution, statisticByPaymentTypesDistributionByPeriod, statisticByPaymentTypesGlobalByPeriod, statisticGlobalByDay} = useSelector((state) => state.cumulative);
  const formatDate = "DD/MM/YYYY HH:mm";
  const [loading, setLoading] = useState(true);
  const siteCount = selectedGroup?.site_count;
  const [siteList, setSiteList] = useState();
  const [equipmentTypeList, setEquipmentTypeList] = useState();
  const [paramList, setParamList] = useState([
    "by_sites",
    "by_type_equipment",
    "by_equipments",
  ]);
  const [paymentTypeList, setPaymentTypeList] = useState([
    "group_intermediate",
    "group_real",
    // 'group_free',
  ]);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format(formatDate)
  );
  const [endDate, setEndDate] = useState(moment().startOf("month").format(formatDate));

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setEquipmentTypeList();
      setSiteList();
      //appel de l'api activity_cumules_get
      if (startDate && endDate) {
        try {
          const promises = [
            dispatch(
              get_activity_cumulative({
                groupId,
                param: JSON.stringify(paramList),
                paymentGroup: JSON.stringify(paymentTypeList),
                startDate: startDate
                  ? moment(startDate, formatDate).unix()
                  : null,
                endDate: endDate ? moment(endDate, formatDate).unix() : null,
              })
            ),
            dispatch(
              get_statistic_by_payment_types_distribution_list({
                groupId,
                limit: 500,
                offset: 0,
                startDate: startDate
                  ? moment(startDate, formatDate).unix()
                  : null,
                endDate: endDate ? moment(endDate, formatDate).unix() : null,
              })
            ),
            dispatch(
              get_statistic_by_payment_types_distribution_by_period_list({
                groupId,
                limit: 500,
                offset: 0,
                startDate: startDate
                  ? moment(startDate, formatDate).unix()
                  : null,
                endDate: endDate ? moment(endDate, formatDate).unix() : null,
              })
            ),
            dispatch(
              get_statistic_by_payment_types_global_by_period_list({
                groupId,
                limit: 500,
                offset: 0,
                startDate: startDate
                  ? moment(startDate, formatDate).unix()
                  : null,
                endDate: endDate ? moment(endDate, formatDate).unix() : null,
              })
            ),
            dispatch(
              get_statistic_global_by_day_list({
                groupId,
                startDate: startDate
                  ? moment(startDate, formatDate).unix()
                  : null,
                endDate: endDate ? moment(endDate, formatDate).unix() : null,
              })
            ),
          ];

          await Promise.all(promises);

          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      }
    }
    if (groupId) {
      fetchData();
    }
  }, [groupId]);

  //*start filters functions
  function handleSiteValueChange(selectedList) {
    setSiteList(selectedList?.length > 0 ? selectedList : null);
  }
  const handleEquipmentType = (selectedList) => {
    setEquipmentTypeList(selectedList?.length > 0 ? selectedList : null);
  };
  const handleDate = (selectedDate) => {
    setStartDate(selectedDate?.length > 0 ? selectedDate[0] : null);
    setEndDate(selectedDate?.length > 0 ? selectedDate[1] : null);
  };
  const onExecuteSubmitBtn = () => {
    try {
      const promises = [
        dispatch(
          get_activity_cumulative({
            groupId,
            param: JSON.stringify(paramList),
            paymentGroup: JSON.stringify(paymentTypeList),
            sites: siteList?.join(","),
            equipmentType: equipmentTypeList?.join(","),
            startDate: startDate ? moment(startDate, formatDate).unix() : null,
            endDate: endDate ? moment(endDate, formatDate).unix() : null,
          })
        ),
        dispatch(
          get_statistic_by_payment_types_distribution_list({
            groupId,
            limit: 500,
            offset: 0,
            startDate: startDate ? moment(startDate, formatDate).unix() : null,
            endDate: endDate ? moment(endDate, formatDate).unix() : null,
            sites: siteList?.join(","),
            equipmentType: equipmentTypeList?.join(","),
          })
        ),
        dispatch(
          get_statistic_by_payment_types_distribution_by_period_list({
            groupId,
            limit: 500,
            offset: 0,
            startDate: startDate ? moment(startDate, formatDate).unix() : null,
            endDate: endDate ? moment(endDate, formatDate).unix() : null,
            sites: siteList?.join(","),
            equipmentType: equipmentTypeList?.join(","),
          })
        ),
        dispatch(
          get_statistic_by_payment_types_global_by_period_list({
            groupId,
            limit: 500,
            offset: 0,
            startDate: startDate ? moment(startDate, formatDate).unix() : null,
            endDate: endDate ? moment(endDate, formatDate).unix() : null,
            sites: siteList?.join(","),
            equipmentType: equipmentTypeList?.join(","),
          })
        ),
        dispatch(
          get_statistic_global_by_day_list({
            groupId,
            startDate: startDate ? moment(startDate, formatDate).unix() : null,
            endDate: endDate ? moment(endDate, formatDate).unix() : null,
            sites: siteList?.join(","),
            equipmentType: equipmentTypeList?.join(","),
          })
        ),
      ];

      Promise.all(promises);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  //*end filters functions
  return (
    <div>
      <div className="filters gap-2 flex flex-row items-end flex-wrap md:max-w-[calc(100vw-500px)]">
        <FilterByEquipmentType onValueChange={handleEquipmentType} />
        {siteCount >1 && <FilterBySearchSite onValueChange={handleSiteValueChange} />}
        <FilterByDate onValueChange={handleDate} />
        <Select
          options={filterStatisticByCategoryOptions}
          placeholder="Statistique catégorie"
          multiple
          width={240}
          onChange={(value) => console.log("Selected:", value)}
          onDeselect={(value) => console.log("Deselected:", value)}
          onSelect={(value, option) => console.log("Selected:", value, option)}
        ></Select>
        <SubmitButton onClick={() => onExecuteSubmitBtn()} />
      </div>
      <StatisticsGlobal dataStatisticGlobalBySites={activityCumuleByGroupId?.by_sites} dataStatisticGlobalByDay={statisticGlobalByDay}/>
      <StatisticsByPaymentType 
        dataStatisticByPaymentTypesDistribution={statisticByPaymentTypesDistribution}
        dataStatisticByPaymentTypesDistributionByPeriod={statisticByPaymentTypesDistributionByPeriod}
        dataStatisticByPaymentTypesGlobalByPeriod={statisticByPaymentTypesGlobalByPeriod}
      />
      {/* <StatisticsByEquipmentType
        data={activityCumuleByGroupId?.by_sites[0]?.by_type_equipment}
      /> */}
      {/* <StatisticsByService /> */}
    </div>
  );
}
