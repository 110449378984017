import Cookies from 'js-cookie';

export const logout = () => {
    const cookieKeysToRemove = Object.keys(Cookies.get()); // Crée un tableau des noms des cookies
    const cookiesToKeep = ["preferenceWebPages"]
    cookieKeysToRemove.forEach(key => {
        if (!cookiesToKeep.includes(key)) {
            Cookies.remove(key); // Supprime tous les cookies sauf "preferenceWebPages"
        }
    });
    const storageKeysToRemove = ["user", "currency_symbol", "access", "refresh"];
    storageKeysToRemove.forEach(key => localStorage.removeItem(key));
};
