import React from "react";
import Label from "../componentsLibrary/label/Label";

export default function OptionCollapsed({onClick, optionsHidden, searchValue, label, width }) {

    return (
        <>
        {label && 
            <Label
                label={label}
                className="h-6 text-left"
            />
        }

        <div
            className={`
                bg-white border border-solid border-primary-color
                text-primary-color cursor-pointer px-4 py-2 rounded-md text-base
                max-w-[120px] ${width ? width : "min-w-52"}
            `}
            onClick={() => onClick()}
        >
            <div className="flex flex-row items-center justify-between truncate">
                <div className="mr-2.5 whitespace-nowrap overflow-hidden text-ellipsis truncate w-full text-left">
                    {searchValue}
                </div> 
                <img 
                    className={`${optionsHidden && 'rotate-180'}`}
                    src={process.env.PUBLIC_URL + "/img/chevron-down.svg"} 
                    alt="icon"
                />
            </div>
        </div>
        </>
    );
};