import React from 'react';
import Button from '../buttons/Button';
import { useTranslation } from "react-i18next";

export default function DismissButton({ onClick }) {
    const { t } = useTranslation();

    return (
        <Button
            color="error"
            onClick={onClick}
        >
            {t("common.transButtonDismiss")}
        </Button>
    )
};
