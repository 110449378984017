import React, { useState, useEffect } from "react";
import { Button } from "../componentsLibrary";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import "./Modal.scss";

const Modal = ({
  isOpen,
  title,
  width,
  height,
  zIndex,
  onOk,
  onOkButtonDisabled,
  onClose,
  onCancel,
  okText,
  cancelText,
  footer,
  style = {},
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const modalRoot = document.getElementById("root");
  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const handleCloseModal = () => {
    onClose();
    setIsModalOpen(false);
  };

  const handleOk = (e) => {
    onOk();
    setIsModalOpen(false);
  };

  const handleCancel = (e) => {
    onCancel();
    setIsModalOpen(false);
  };

  const modalContent = (
    <div
      className={`modal ${isModalOpen ? "is-open" : ""}`}
      style={{ zIndex: zIndex }}
    >
      <div
        className="modal-overlay"
        style={{ ...style.modalOverlay }}
        onClick={handleCloseModal}
      ></div>
      <div
        className="modal-content flex-display flex-column justify-content-between "
        style={{ width: width, height: height, ...style.modalContent }}
        role="dialog"
        tabIndex="-1"
      >
        <div className="flex-display flex-column">
        <div className="modal-header" style={{ ...style.modalHeader }}>
          <div className="modal-title" style={{ ...style.modalTitle }}>
            {title}
          </div>
          <div
            className="modal-close"
            style={{ ...style.modalClose }}
            onClick={handleCloseModal}
          >
            <span>&times;</span>
          </div>
        </div>
        <div className="modal-body" style={{ ...style.modalBody }}>
          {props.children}
        </div></div>
        <div className="modal-footer" style={{ ...style.modalFooter }}>
          {footer}
          <div
            className="flex-display flex-row justify-content-center align-items-stretch"
            style={{ marginTop: "10px", gap:"10px"}}
          >
            {onCancel && (
              <Button
                type="primary"
                htmltype="reset"
                className='bg-error-color-600 transition duration-150 h-[40px] ease-in-out border-white text-white hover:bg-error-color hover:text-white hover:border-white focus:text-white focus:bg-error-color focus:border-white'
                onClick={onCancel}
              >
                {cancelText}
              </Button>
            )}
            {onOk && (
              <Button
                htmltype="submit"
                type="primary"
                className='bg-success-color transition duration-150 h-[40px] ease-in-out border-white text-white hover:bg-success-color-600 hover:text-white hover:border-white focus:text-white focus:bg-success-color focus:border-white'
                onClick={onOk}
                disabled={onOkButtonDisabled}
              >
                {okText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return createPortal(modalContent, modalRoot);
};

Modal.defaultProps = {
  zIndex: 1000,
  width: 600,
  okText: "Enregistrer",
  cancelText: "Annuler",
  onOkButtonDisabled: false
};

Modal.propTypes = {
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  onOkButtonDisabled: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Modal;
