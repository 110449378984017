import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { create_cash_boxes_by_group } from "../../../slices/statementSlice";
import {
  Button,
  Input,
  Modal,
  Select,
} from "../../../../modUtils/components/componentsLibrary/componentsLibrary";
import { get_sites_by_group_paginated } from "../../../../modManager/slices/managerSlice";
import { get_equipments_gb_sites_by_group_paginated } from "../../../../modEquipment/slices/equipmentSlice";
import SubmitButton from "../../../../modUtils/components/buttons/submitButton/SubmitButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaExclamationCircle, FaPlus } from "react-icons/fa";

const AddNewCashBox = ({ refresh, site }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { groupId, sites } = useSelector((state) => state.manager);
  const [loading, setLoading] = useState(false);
  const [cashBoxEquipments, setCashBoxEquipments] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [creationError, setCreationError] = useState(null);
  const formReset = useRef(null);
  const CashboxSchema = Yup.object().shape({
    name: Yup.string().required(t("addNewCashbox.addCashboxInputError")),
  });
  useEffect(() => {
    dispatch(get_sites_by_group_paginated({ groupId, limit: 100, offset: 0 }));
  }, [dispatch, groupId]);

  const createCashBox = async (values, resetForm) => {
    setCreationError(null);
    setLoading(true);
    try {
      const params = {
        groupId,
        name: values.name,
        site: site,
        isOld: false,
        cashBoxEquipments: cashBoxEquipments.map((equipment) => ({
          equipment: equipment.id,
        })),
      };
      await dispatch(create_cash_boxes_by_group(params)).unwrap();
      refresh();
      setModalVisible(false);
    } catch (error) {
      setCreationError(error?.data?.detail);
    } finally {
      setLoading(false);
    }
  };

  const fetchEquipments = async () => {
    try {
      const equipmentData = await dispatch(
        get_equipments_gb_sites_by_group_paginated({
          groupId,
          limit: 100,
          offset: 0,
        })
      ).unwrap();
      //find site object from site selection
      const siteDataSelection = equipmentData.results.find(
        (item) => item.id === site
      );
      //get equipements of site selection
      const equipmentSiteData = siteDataSelection.equipments;
      setEquipments(equipmentSiteData);
    } catch (error) {
      console.error("Error fetching equipment:", error);
    }
  };
  const handleEquipementValueChange = (selectedValue) => {
    if (!Array.isArray(selectedValue)) {
      selectedValue = [selectedValue]; //s'assurer que c'est un array
    }
    const selectedEquipments = selectedValue
      .map((selectedId) => {
        const foundEquipment = equipments.find(
          (equipment) => Number(equipment.id) === Number(selectedId)
        ); //recherche de l equipement avec l id
        return foundEquipment;
      })
      .filter(Boolean); //filtrer les undifined
    setCashBoxEquipments((prevEquipement) => {
      const newEquipment = selectedEquipments.filter(
        (selectEq) =>
          !prevEquipement.some((prevEq) => prevEq.id === selectEq.id)
      ); ///verification des doublons
      const Equipements = [...prevEquipement, ...newEquipment];
      return Equipements; // effectuer la selection multiple ajouter la valeur recente
    });
  };
  useEffect(() => {
    if (!modalVisible) {
      setCashBoxEquipments([]);
      setCreationError(null);
    }
  }, [modalVisible]);
  return (
    <>
      <Button
        onClick={() => {
          setModalVisible(true), fetchEquipments();
        }}
        className="flex items-center group hover:fill-white"
      >
        <FaPlus className="mt-0 fill-primary-color group-hover:fill-white" />

        <span className="APP_tablePre-btnLabel ml-2">
          {t("addNewCashbox.addCashboxTitle")}
        </span>
      </Button>
      <Modal
        isOpen={modalVisible}
        onClose={() => {
          formReset.current();
          setModalVisible(false);
        }}
        width={700}
        zIndex={2000}
      >
        <div className="flex items-center justify-center">
          <span className="font-bold text-base items-center justify-center uppercase text-primary-color">
            {t("addNewCashbox.addCashboxTitle")}
          </span>
        </div>

        <br />

        <Formik
          validationSchema={CashboxSchema}
          initialValues={{ name: "" }}
          onSubmit={(values, { resetForm }) => {
            createCashBox(values, resetForm);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            resetForm,
          }) => {
            formReset.current = resetForm;
            return (
              <form i="form" noValidate onSubmit={handleSubmit} ref={formReset}>
                <div className="flex flex-row gap-8 mt-8">
                  <div className="items-start">
                    <Input
                      label={
                        <span className="text-black normal-case">
                          {t("addNewCashbox.cashboxName") + "*"}
                        </span>
                      }
                      value={values.name}
                      name="name"
                      onChange={handleChange}
                      placeholder={t("addNewCashbox.cashboxName")}
                    />

                    <p className="text-red-500 text-xs">
                      {errors.name && touched.name && errors.name}
                    </p>
                  </div>
                  <div className="items-end">
                    <Select
                      selectLabel={
                        <span className="text-black normal-case">
                          {t("addNewCashbox.cashboxEquipement")}
                        </span>
                      }
                      value={cashBoxEquipments.map((eq) => eq.id)}
                      options={
                        Array.isArray(equipments)
                          ? equipments.map((eq) => ({
                              value: eq.id,
                              label: eq.name,
                            }))
                          : []
                      }
                      onSelect={handleEquipementValueChange}
                      multiple
                      placeholder={t("addNewCashbox.cashboxEquipementSelect")}
                    />
                  </div>
                </div>

                <div className="grid justify-items-center mt-12 ">
                  <SubmitButton
                    label={
                      <span className="uppercase">
                        {t("addNewCashbox.addCashboxButton")}
                      </span>
                    }
                  />
                  {creationError && (
                    <div className="items-center gap-1">
                      {" "}
                      <FaExclamationCircle className="w-4" fill="red" />
                      <span className="text-red-500 text-xs ">
                        {creationError}
                      </span>
                    </div>
                  )}
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default AddNewCashBox;
