import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import Language from "../../locale/language";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const colorVariants = {
    dark: 'text-gray-500',
    light: 'text-white'
};

export function CountryFlag({ flagIcon, languageLabel, countryCode, onClick, color }) {
    return (
        <div onClick={onClick} key={countryCode} className="w-fit mx-[5px] flex flex-col items-center">
            <img className="w-[25px]" src={process.env.PUBLIC_URL + `/img/flags/${flagIcon}`}/>
            <div className={`text-[9px] font-medium ${colorVariants[color]}`}>{languageLabel}</div>
        </div>
    );
};

export default function LanguageSwitcher({ color }) {
    const { i18n } = useTranslation();
    const [showFlags, setShowFlags] = useState(false);

    const toggleFlags = () => {
        setShowFlags(!showFlags);
    };

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setShowFlags(false);
    };

    return (
        <div className="flex flex-row justify-end">
            <div 
                className={`w-[25px] h-[25px] text-center ${colorVariants[color]}`}
                onClick={toggleFlags}
            >
                {showFlags ?
                    <FaArrowRight className="h-[25px]"/>
                :
                    <FaArrowLeft className="h-[25px]"/>
                }
            </div>
           

            <div className="flag-list flex flex-row justify-end">
                {showFlags ? (
                    <>
                    <CountryFlag
                        flagIcon="en.png"
                        languageLabel="English"
                        countryCode={Language.EN}
                        onClick={() => changeLanguage(Language.EN)}
                        color={color}
                    />
                    <CountryFlag
                        flagIcon="fr.png"
                        languageLabel="Français"
                        countryCode={Language.FR}
                        onClick={() => changeLanguage(Language.FR)}
                        color={color}
                    />
                    </>
                ) : (
                    <CountryFlag
                        flagIcon={i18n.language === Language.EN ? "en.png" : "fr.png"}
                        countryCode={i18n.language}
                    />
                )}
            </div>

        </div>
    );
};