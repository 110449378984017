import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Select,
  Pagination,
  Spinner,
} from "ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary";
import SubmitButton from "ck_commun/src/app/modUtils/components/buttons/submitButton/SubmitButton";
import FilterByDate from "ck_commun/src/app/modUtils/components/filters/FilterByDate";
// import FilterByPaymentType from "ck_commun/src/app/modUtils/components/filters/FilterByPaymentType";
import FilterBySearchSite from "ck_commun/src/app/modManager/components/filterBySearchSite/FilterBySearchSite";
// import AssignPickuperModal from "../components/assignPickuperModal/AssignPickuperModal";
// import ManagePickuperButton from "../components/managePickuperButton/ManagePickuperButton";
import { message } from "antd";
import AlertMessage from "ck_commun/src/app/modUtils/components/AlertMessage";
import { get_statements_by_group_paginated } from "../slices/statementSlice";
import PickupsTable from "../components/pickupsTable/PickupsTable";
import moment from "moment";
import FilterByType from "../../modUtils/components/filters/FilterByType";
import { useTranslation } from "react-i18next";
import { eEquipmentType } from "ck_commun/src/commun_global/enums";
import ManageCashBoxButton from "../components/ManageCashBoxes/ManageCashBoxButton";

export default function PickupsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { statements, statementsCount } = useSelector(
    (state) => state.statement
  );
  const { groupId, selectedGroup } = useSelector((state) => state.manager);
  const [loading, setLoading] = useState(false);
  const siteCount = selectedGroup?.site_count;
  const [siteList, setSiteList] = useState([]);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [equipmentTypeList, setEquipmentTypeList] = useState();
  // const [filterEquipmentTypeList, setFilterEquipmentTypeList] = useState();
  // const [filterCaisseTypeList, setFilterCaisseTypeList] = useState();
  const [cashBoxes, setCashBoxes] = useState([]);
  const formatDate = "DD/MM/YYYY HH:mm";
  const [startDate, setStartDate] = useState(
    moment().subtract(6, "days").startOf("day").format(formatDate)
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format(formatDate)
  );
  // // filtre type d'equipement temporaire
  // const lineNumbers = Object.keys(eEquipmentType).map(Number);
  // const filterEquipmentTypeList = lineNumbers.map(index => ({
  //     label: t(eEquipmentType[index]),
  //     value: index
  // }));
  const [selectForm, setSelectForm] = useState({
    equipments: equipmentTypeList?.join(","),
    sites: siteList?.join(","),
    dateStart: startDate,
    dateEnd: endDate,
    cash_boxes: cashBoxes?.join(","),
  });

  const handleDateChange = (selectedDate) => {
    setStartDate(selectedDate?.length > 0 ? selectedDate[0] : null);
    setEndDate(selectedDate?.length > 0 ? selectedDate[1] : null);
  };
  function handleSiteValueChange(selectedList) {
    setSiteList(selectedList?.length > 0 ? selectedList : null);
  }
  // const handleEquipmentType = (selectedList) => {
  //     setEquipmentTypeList(selectedList?.length > 0 ? selectedList : null);
  // };

  // const handleCashBox = (selectedList) => {
  //     setCashBoxes(selectedList?.length > 0 ? selectedList : null);
  // };

  const onExecuteSubmitBtn = () => {
    getStatements(selectForm);
  };

  // const addUniqueValue = (existingArray, newArray) => {
  //     const existingValues = new Set(existingArray.map(item => item.value));

  //     // Ajoute les éléments de newArray s'ils sont uniques
  //     newArray.forEach(item => {
  //         if (!existingValues.has(item.value)) {
  //             existingArray.push(item);
  //             existingValues.add(item.value); // Met à jour le Set avec la nouvelle valeur
  //         }
  //     });

  //     return existingArray;
  // }

  // const getFilterEquipmentTypeList = (response) => {
  //     const uniqueEquipments = new Map();

  //     response.results.forEach(result => {
  //         result.equipment_statements.forEach(statement => {
  //             const { id, name } = statement.equipment;
  //             if (!uniqueEquipments.has(id)) {
  //                 uniqueEquipments.set(id, name);
  //             }
  //         });
  //     });

  //     const uniqueEquipmentsList = Array.from(uniqueEquipments, ([id, name]) => ({ value: id, label: name }));

  //     if (filterEquipmentTypeList) {
  //         setFilterEquipmentTypeList(addUniqueValue(filterEquipmentTypeList, uniqueEquipmentsList));
  //     }else {
  //         setFilterEquipmentTypeList(uniqueEquipmentsList);
  //     }
  // };

  // const getFilterCaisseTypeList = (response) => {
  //     const uniqueCashBoxes = new Map();

  //     response?.results?.forEach(result => {
  //         if (!uniqueCashBoxes.has(result.cash_box.id)) {
  //             uniqueCashBoxes.set(result.cash_box.id, result.cash_box.name);
  //         }
  //     });

  //     const uniqueCashBoxesList = Array.from(uniqueCashBoxes, ([id, name]) => ({ value: id, label: name }));

  //     if (filterCaisseTypeList) {
  //         setFilterCaisseTypeList(addUniqueValue(filterCaisseTypeList, uniqueCashBoxesList));
  //     }else {
  //         setFilterCaisseTypeList(uniqueCashBoxesList);
  //     }
  // };

  const getStatements = async (values) => {
    setLoading(true);
    try {
      const response = await dispatch(
        get_statements_by_group_paginated({
          groupId: groupId,
          dateStart: startDate ? moment(startDate, formatDate).unix() : null,
          dateEnd: endDate ? moment(endDate, formatDate).unix() : null,
          cashBoxes: values?.cashBoxes ? values?.cashBoxes : null,
          sites: siteList?.join(","),
          equipments: values?.equipments ? values?.equipments : null,
          limit: values.limit ? values.limit : limit,
          offset: values.offset || values.offset === 0 ? values.offset : offset,
        })
      ).unwrap();
      // getFilterEquipmentTypeList(response);
      // getFilterCaisseTypeList(response);
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error?.status}
            alertMessage={t("PickupsPage.anErrorOccurred")}
            errorDetail={error?.data?.detail}
          />
        ),
      });
    } finally {
      setLoading(false);
    }
  };

  // Fonction de gestion de changement de pagination
  const handlePaginationChange = (page, pageSize) => {
    const newOffset = (page - 1) * pageSize;
    setOffset(newOffset);
    if (newOffset < 0) {
      setCurrentPage(1);
    } else {
      setCurrentPage(page);
    }
    setLimit(pageSize);

    const values = {
      equipments: equipmentTypeList?.join(","),
      sites: siteList?.join(","),
      dateStart: startDate ? moment(startDate, formatDate).unix() : null,
      dateEnd: endDate ? moment(endDate, formatDate).unix() : null,
      cash_boxes: cashBoxes?.join(","),
      limit: pageSize,
      offset: newOffset,
    };

    getStatements(values);
  };

  useEffect(() => {
    if (groupId) {
      getStatements(selectForm);
    }
  }, [groupId, startDate, endDate]);

  // const [paymentTypeList, setPaymentTypeList] = useState();

  // const searchPickuperOptions = [
  //     { label: "Identifiant", value: "identifier" },
  //     { label: "Nom", value: "name" },
  // ];

  // const handlePaymentType = (selectedList) => {
  //     setPaymentTypeList(selectedList?.length > 0 ? selectedList : null);
  // };

  // const handlePressEnter = (selectedOption, searchValue) => {
  //     //console.log(selectedOption, searchValue);
  // };

  return (
    <>
      <div className="flex flex-col sm:flex-row items-end gap-2 filters">
        {/* <FilterByType
                onValueChange={handleEquipmentType}
                options={filterEquipmentTypeList}
                selectLabel={t("PickupsPage.equipmentType")}
            /> */}
        {siteCount > 1 && (
          <FilterBySearchSite onValueChange={handleSiteValueChange}/>
        )}
        <FilterByDate
          onValueChange={handleDateChange}
          defaultPeriod="lastSevenDays"
        />

        {/* <FilterByPaymentType onValueChange={handlePaymentType}/> */}
        {/* <FilterByType onValueChange={handleCashBox} options={filterCaisseTypeList} selectLabel="Caisse"/> */}
        <SubmitButton onClick={() => onExecuteSubmitBtn()} />
      </div>

      {/* <Select
            defaultActiveFirstOption={true}
            options={searchPickuperOptions}
            onChange={(value) => console.log("Selected:", value)}
            onDeselect={(value) => console.log("Deselected:", value)}
            onSelect={(value, option) => console.log("Selected:", value, option)}
            selectLabel={"Recherche"}
            searchInput
            onPressEnter={handlePressEnter}
        /> */}

      <div className="flex flex-row justify-between whitespace-nowrap mt-8">
        <div className="flex flex-row justify-start">
            <Pagination
              defaultCurrent={currentPage}
              defaultPageSize={limit}
              showQuickJumper={true}
              showTotal={true}
              total={statementsCount}
              onChange={handlePaginationChange}
              onShowSizeChange={handlePaginationChange}
              hideOnSinglePage={true}
            />
        </div>

        <div className="flex flex-row justify-end mb-2.5">
          {/* <ManagePickuperButton/> */}
          {/* <ExportButton/> */}
          {/* <PrintButton/> */}
          <ManageCashBoxButton />
        </div>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <PickupsTable
          statements={statements}
          dateStart={startDate}
          dateEnd={endDate}
          filterSite={siteList}
        />
      )}
    </>
  );
}
