import React from "react";
import { useSelector } from 'react-redux';
import { Modal } from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import SiteUpdateModel from "../siteUpdateModal/SiteUpdateModal";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "../../../modUtils/functions/handlePermissions";
import CKToolTip from "../../../modUtils/components/CKToolTip/CKToolTip";
import "./SiteManageModal.scss";
import Button from "../../../modUtils/components/buttons/Button";
import ButtonDisabled from "../../../modUtils/components/buttons/ButtonDisabled";
import AdminLink from "../../../modUtils/components/adminLink/AdminLink";

export default function SiteManageModal({
  site,
  modalVisible,
  handleModal,
  closeModal,
  mapModal,
}) {
  const navigate = useNavigate();
  const { selectedGroup } = useSelector((state) => state.manager);
  const permissions = selectedGroup?.permissions;
  const verifyPermission = hasPermission(
    "mod_cumulative:show_statistic",
    permissions
  );
  const groupPermissions = selectedGroup?.plan?.permissions;
  const verifyGroupPermission = hasPermission(
    "mod_cumulative:show_statistic",
    groupPermissions
  );

  const modalFooter = (
    <div className="flex-display flex-column" style={{ gap: "10px" }}>
      <div className="flex-display flex-row" style={{ gap: "10px" }}>
        <SiteUpdateModel
          closeModal={closeModal}
          site={site}
          mapModal={mapModal}
        />
        {verifyPermission && verifyGroupPermission ?
            <Button
                onClick={() => (navigate(`/activity/ca`))}
                color="primary"
                className="h-10 w-full mx-[5px]"
                size="sm"
            >
                ACTIVITÉ/CA
            </Button>
        :
            <ButtonDisabled
                onClick={verifyPermission ? () => (navigate(`/activity/ca`)) : null}
                className="h-10 w-full whitespace-nowrap mx-[5px]"
                size="sm"
            >
                ACTIVITÉ/CA
            </ButtonDisabled>
        }
      </div>
      {/* TODO : a ajouter quand les pages relevé et stats seront prêtes */}
      {/* <div className="flex-display flex-row" style={{ gap: "10px" }}>
        <Button className="button" type="primary" block onClick={handleModal}>
          RELEVÉS
        </Button>
        <Button className="button" type="primary" block onClick={handleModal}>
          STATISTIQUES
        </Button>
      </div> */}
    </div>
  );

  return (
    <div
      className="site-panel-header-title"
      onClick={(e) => e.stopPropagation()}
    >
      {!mapModal && (
        <div
          className="whitespace-nowrap mr-8 min-w-64 w-64 lg:w-64 xl:w-72 2xl:w-80 text-neutral-100 font-bold text-left text-lg text-ellipsis cursor-pointer"
          onClick={handleModal}
        >
          <CKToolTip placement="topLeft" trigger="hover" content={site.name}>
            <span>{site.name}</span>
          </CKToolTip>
        </div>
      )}

      <Modal
        title="Gestion d'un site"
        isOpen={modalVisible}
        onClose={closeModal}
        footer={modalFooter}
      >
         <AdminLink
                    type="button"
                    label="Lien Admin Site"
                    adminPath={`mod_manager/site/${site.id}/change/`}
                    className="whitespace-nowrap text-xs leading-3 absolute top-8 mt-8"
                /> 
        <span
            className=" text-primary-color text-muted absolute top-4 left-4 "
        >
            id: {site.id}
        </span>
        <div className="siteManageInfos info-container flex-display flex-column justify-content-around mt-8">
          <span>{site?.name}</span>
          <span>{site?.description}</span>
          {(site?.location_address?.street_number ||
            site?.location_address?.street) && (
            <span>
              {`${
                site?.location_address?.street_number
                  ? site?.location_address?.street_number
                  : ""
              } ${
                site?.location_address?.street
                  ? site?.location_address?.street
                  : ""
              }`}
            </span>
          )}
          {(site?.location_address?.zipcode ||
            site?.location_address?.city) && (
            <span>
              {site?.location_address?.zipcode
                ? site?.location_address?.zipcode
                : ""}{" "}
              {site?.location_address?.city ? site?.location_address?.city : ""}
            </span>
          )}
        </div>
      </Modal>
    </div>
  );
}
