// on set la variable isProd a true quand le site est en ligne , on la passe a false uniquement si on est sur l'url de dev
// je prefère la mettre a true par défaut pour éviter les risques

const isProd = process.env.NODE_ENV === 'production' && window.location.origin !== 'https://dev.laundry.cksquare.fr';
const srcImg = process.env.PUBLIC_URL + "/img/menuIcons/";
const appLogo = process.env.PUBLIC_URL + "/laundryLogoSquare.png";
const appName = "Laundry Manager"
const appShortName = "Laundry"
const contactPhoneNumber = "0 473 289 246"

const UrlMobileAppApple = ""
const UrlMobileAppAndroid = ""

export { isProd, srcImg, appLogo, UrlMobileAppApple, UrlMobileAppAndroid, appName, appShortName, contactPhoneNumber };
