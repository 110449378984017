import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import {
  Input,
  Button,
} from "../../modUtils/components/componentsLibrary/componentsLibrary";
import { useDispatch } from "react-redux";
import { create_tokens_from_email, create_tokens_from_refresh } from "../slices/authenticationSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AlertMessage from "../../modUtils/components/AlertMessage";
import Cookies from 'js-cookie';
import { logout } from "../../modUtils/functions/logout";
import ButtonLoginWithDemoAccount from "../../modUtils/components/buttons/ButtonLoginWithDemoAccount";

const LoginForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  // connexion en utilisant le cookie
  const loginWithCookie = async () => {
    try {
        await dispatch(create_tokens_from_refresh(Cookies.get('token_refresh'))).unwrap();
        navigate("/home");
    } catch (error) {
        logout(navigate);
    }
  };

  useEffect(() => {
    if (Cookies.get('token_refresh')) {
        loginWithCookie();
    }
  }, [Cookies.get('token_refresh')]);
  
  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Ce champs est obligatoire")
      .email("Format email invalide"),
    password: Yup.string()
      .required("Ce champs est obligatoire")
      .min(8, "Mot de passe très court"),
  });

  const handleSubmit = async (values, resetForm) => {
    setIsSubmitting(true);
    try {      
      const response = await dispatch(create_tokens_from_email(values)).unwrap();
      
      // si la checkbox RememberMe est cochée alors nous créons un cookie avec la valeur du refresh pendant 6 mois
      // si non nous créons un cookie avec la valeur du refresh pour la session
      if(rememberMe === true) {
        Cookies.set('token_refresh', response.refresh, { expires: 180 });
      } else {
        Cookies.set('token_refresh', response.refresh);
      }
        
      // Si la requête est réussie, naviguer vers "/home"
      navigate("/home");
    } catch (error) {
      message.error({
        content: (
          <AlertMessage
            status={error.status}
            alertMessage={"Authentification échouée!"}
            errorDetail={error.data.detail}
          />
        ),
      });
    } finally {
      setIsSubmitting(false);
      if (typeof resetForm === "function") {
        resetForm();
      }
    }
  };
  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
    //*TODO ajouter la fonction pour maintenir la connexion
  };
  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{ email: "", password: "" }}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
        }) => (
          <div className="login">
            <div className="form">
              <form 
                id="form"
                noValidate
                onSubmit={handleSubmit}
                className="max-w-[300px]"
              >
                <Input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder="Email"
                  className="form-control inp_text md:w-[300px]"
                  id="email"
                />
                <p className="error text-error-color">
                  {errors.email && touched.email && errors.email}
                </p>
                <Input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder="Mot de passe"
                  className="form-control"
                />
                <p className="error text-error-color">
                  {errors.password && touched.password && errors.password}
                </p>
                <div className="text-[12px] leading-[10px] text-[#5CA9DD] text-left align-middle mb-[20px]">
                  <input
                    type="checkbox"
                    id="rememberMe"
                    value={rememberMe}
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                    style={{ marginRight: "10px" }}
                  />
                  <label htmlFor="rememberMe">
                    {t("loginCommon.loginRememberMe")}
                  </label>
                </div>

                <Button
                  htmltype="submit"
                  type="primary"
                  style={{ borderRadius: "15px", height: "40px" , textTransform : "uppercase"}}
                  block
                  disabled={isSubmitting || !isValid}
                  className="text-xs md:text-sm"
                >
                {t("loginCommon.loginButton")}
                </Button>
              </form>
            </div>
          </div>
        )}
      </Formik>

      <ButtonLoginWithDemoAccount
        className="w-full md:w-[300px] max-w-[300px] h-10 mt-5 cursor-pointer !rounded-2xl uppercase text-xs md:text-sm"
        onClick={handleSubmit}
      >
     {t("loginCommon.loginDemoButton")}
      </ButtonLoginWithDemoAccount>
    </>
  );
};

export default LoginForm;
