import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Spin, Checkbox, Popover, Tooltip, Space } from "antd";
import { get_sites_by_group_paginated } from "../../slices/managerSlice";

import "./FilterBySearchSite.scss";
const { Option } = Select;

const FilterBySearchSite = ({ onValueChange, label }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const defaultLimit = 25;
  const [sitesOptions, setSitesOptions] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState();
  const [selectedValues, setSelectedValues] = useState([]); // Changed to handle multiple selections
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(true);
  const dropdownRef = useRef(null); // Ref to access dropdown element
  const { groupId, selectedGroup } = useSelector((state) => state.manager);
  const siteCount = selectedGroup.site_count;
  const [singleSiteLabel, setSingleSiteLabel] = useState();
  //getSitesByGroup appelle l'api get_sites_by_group_paginated pour récupérer la liste des groupes et ensuite stocke les données dans sitesOptions sous forme {value: groupId, label: groupName}
  const getSitesByGroup = async (
    searchValue = null,
    clearData = false,
    resetPage = false
  ) => {
    const requestParams = {
      limit: defaultLimit,
      offset: resetPage ? 0 : (page - 1) * defaultLimit,
      groupId: groupId,
    };
    if (searchValue !== null) {
      //ne pas passer le filtre par name que quand il est passé en props de getSitesByGroup
      requestParams.name = searchValue;
    }
    let sites = [];
    try {
      setLoading(true);
      const response = await dispatch(
        get_sites_by_group_paginated(requestParams)
      ).unwrap();
      if (response.results) {
        sites = response.results;
        const newData = sites?.map((site) => ({
          value: site.id,
          label: site.name,
        }));
        if (siteCount === 1 && sitesOptions.length > 0) {
          setSingleSiteLabel(sitesOptions[0].label);
        } else setSingleSiteLabel();
        // Mise à jour de l'état HasMoreDataToLoad en fonction de la valeur de "next" dans la réponse de l'API
        setHasMoreDataToLoad(response?.next !== null);
        if (clearData) {
          setSitesOptions(newData);
        } else if ((searchValue && page === 1) || page === 1) {
          setSitesOptions(newData);
        } else {
          setSitesOptions((prevData) => [...prevData, ...newData]);
        }
      }
      return sites;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (page > 1 && groupId) {
      getSitesByGroup(searchInputValue);
    }
  }, [page, groupId]);

  //handleSearch fonction appelée quand on cherche un site, càd la valeur de searchInputValue change
  const handleSearch = (newValue) => {
    setSearchInputValue(newValue);
    setPage(1);
    if (
      newValue.length >= 3 &&
      !(newValue.length === 3 && newValue[2] === " ")
    ) {
      //faire l'appel à l'api que quand searchInputValue contient 3 caractères ou plus, et le 3ème caractère n'est pas vide
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      setSearchTimeout(
        setTimeout(() => {
          getSitesByGroup(newValue, true, true);
        }, 400) //searchTimeOut utilisé pour ne pas appeler l'api à chaque fois qu'on écrit un caractère de plus, limiter les appels inutiles par un temps d'attente de 400ms
      );
    }
  };

  //handleSelect appelée quand on sélectionne ou désélectionne un site
  const handleSelect = (newValues) => {
    setSelectedValues(newValues);
    onValueChange(newValues);
    setPage(1); // Reset page when selected site changes
  };
  
  //handlePopupScroll fonction appelée quand on scrolle dans le dropdown du select
  const handlePopupScroll = (event) => {
    const { target } = event;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      hasMoreDataToLoad
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  
  // handleDropdownVisibleChange :fonction ajoutée pour recharger la liste des sites (pour ne pas afficher la dernière liste recherchée)
  const handleDropdownVisibleChange = (visible) => {
    setPage(1); // Reset the page number
    setSearchInputValue();
    if (visible) {
      if (dropdownRef.current) {
        dropdownRef.current.scrollTop = 0; // Scroll dropdown to top
      }
      getSitesByGroup(null, true, true); // Fetch sites, clear search value, clear previous data, and reset page
    }
  };

  // Get the labels of selected sites as a list
  const selectedSitesList = (
    <div className="w-64">
      {selectedValues.length > 0 ? (
        <>
          <p className="text-primary-color-400 font-bold">
            {selectedValues.length} Site(s) sélectionné(s) :
          </p>

          <ul className="list-disc list-outside max-h-56 overflow-y-auto pl-5 text-neutral-color-600 w-full ">
            {sitesOptions
              .filter((site) => selectedValues.includes(site.value))
              .map((site) => (
                <li className="text-xs w-full" key={site.value}>
                  <span>{site.label}</span>
                  <span
                    className="ml-2 place-self-end cursor-pointer text-right text-error-color-600 hover:text-error-color-700 hover:font-bold"
                    onClick={() => handleDeselect(site.value)}
                  >
                    X
                  </span>
                </li>
              ))}
          </ul>
        </>
      ) : (
        <p className="text-primary-color-400 font-bold">
          Aucun site sélectionné
        </p>
      )}
    </div>
  );
  //appelé quand on déselectionne une option
  const handleDeselect = (siteId) => {
    const newSelectedValues = selectedValues.filter(
      (value) => value !== siteId
    );
    setSelectedValues(newSelectedValues);
    onValueChange(newSelectedValues);
    //eset page number
  };
  //appelé au changement du checkbox value
  const onChangeCheckbox = (event, siteOption) => {
    const newValue = event.target.checked
      ? [...selectedValues, siteOption.value]
      : selectedValues.filter((value) => value !== siteOption.value);
    handleSelect(newValue);
  };

  return (
    <>
      <div className="select-site-container w-full sm:w-auto mr-0">
        <div className="lg:h-9 ">{label}</div>
        <Select
          mode={siteCount === 1 ? undefined : "multiple"}
          // Enable multiple selection when we have more than 1 site
          showSearch
          value={siteCount === 1 ? singleSiteLabel : selectedValues}
          // Set the selected values
          defaultActiveFirstOption={false}
          filterOption={false}
          placeholder={"Rechercher par site"}
          onSearch={handleSearch}
          onPopupScroll={handlePopupScroll}
          onChange={handleSelect}
          notFoundContent={"Aucun site ne correspond à votre recherche"}
          allowClear={siteCount !== 1}
          listHeight={200}
          onDropdownVisibleChange={handleDropdownVisibleChange}
          dropdownRender={(menu) => (
            <div ref={dropdownRef}>
              {menu}{" "}
              <div className="h-4 mb-2 flex justify-center">
                {loading && <Spin />}
              </div>
            </div>
          )}
          maxTagCount={0} // Hides the tags
          maxTagPlaceholder={() => <span>Rechercher par site</span>}
          className={`w-full sm:w-72 select-customize-input ${
            siteCount > 1 || !loading ? "" : "pointer-events-none"
          }`} //désactiver le fonctionnement du select quand on a un seul site
          suffixIcon={
            siteCount > 1 && (
              <img
                src={process.env.PUBLIC_URL + "/img/design/zoom.svg"}
                alt="zoom"
              />
            )
          } //ne pas afficher l'icone de recherche quand on a un seul site
          // disabled={siteCount !== 1}
        >
          {siteCount > 1 &&
            sitesOptions?.map((siteOption) => (
              <Option key={siteOption.value} value={siteOption.value}>
                <span className="text-xs">{siteOption.label}</span>
              </Option>
            ))}
        </Select>
        <div className="w-fit relative top-1 h-0">
          {siteCount > 1 && (
            <Popover
              placement="bottomLeft"
              trigger="hover"
              content={selectedSitesList}
            >
              <div className="text-xs font-semibold text-neutral-color-400 ">
                {selectedValues.length} sites sélectionnés / {siteCount} sites
              </div>
            </Popover>
          )}
        </div>
      </div>
    </>
  );
};

export default FilterBySearchSite;
