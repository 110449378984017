import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BrandHeader from "../brandHeader/BrandHeader";
import UserSubscriptionPlanModal from "ck_commun/src/app/modManager/components/UserSubscriptionPlanModal";
import AdminLink from "../adminLink/AdminLink";
import LanguageSwitcher from "../languageSwitcher/LanguageSwitcher";
import SelectGroup from "../../../modManager/components/SelectGroup/SelectGroup";
import { get_user_details } from "ck_commun/src/app/modAuthentication/slices/authenticationSlice";
import { Menu } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';

export default function BurgerMenu({ 
    collapsed,
    menuItems, 
    defaultOpenItemsKeys, 
    defaultActiveKey, 
    logoSrc,
    logoAndTextSrc = null,
    toggleMobileMenu
}) {
    const menuKey = sessionStorage.getItem('activeMenuKey')
    const extractParentKey = (itemKey) => {
        return itemKey?.split('/')[0];
    };
    const { selectedGroup, loadingGroup } = useSelector((state) => state.manager);
    const [openKeys, setOpenKeys] = useState(extractParentKey(menuKey)|| defaultOpenItemsKeys || []); //menu(ayant des sous-menus) ouvert par défaut
    const [activeMenuKey, setActiveMenuKey] = useState(menuKey || defaultActiveKey || menuItems[0].key); //menu actif
    const [isPlansModalVisible, setIsPlansModalVisible] = useState(false);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { user } = useSelector((state) => state.authentication);

    // handleOpenKeysChange to Handle opening/closing of submenu items
    const handleOpenKeysChange = (keys) => {
        setOpenKeys(keys);
    };

    useEffect(() => {
        async function fetchData() {
          await dispatch(get_user_details(localStorage.getItem("user")));
          setLoading(false);
        }
        fetchData();
      }, []);

    return (
        <div className="bg-primary-color !w-screen h-screen fixed z-50 overflow-y-auto pb-[100px]">
        <div className={`flex flex-col items-center mt-5 mb-[30px] mx-[13px] tel:m-0 tel:mt-2.5`}>
            {logoAndTextSrc !== null && (
                <BrandHeader
                    logoSrc={process.env.PUBLIC_URL + logoSrc}
                    logoAndTextSrc={process.env.PUBLIC_URL + logoAndTextSrc}
                    collapsed={collapsed}
                />
            )}


            {!collapsed && !loadingGroup && (
                <div className={`text-white text-center text-base xs:text-xs sm:text-sm font-bold`}>{selectedGroup?.name}</div>
            )}

            <div
                className="cursor-pointer "
                onClick={() => setIsPlansModalVisible(true)}
            >
                {selectedGroup?.plan?.icon && (
                    <img
                        src={selectedGroup?.plan?.icon}
                        alt={selectedGroup?.plan?.plan_name}
                        style={{filter:"brightness(100)"}}
                        width={"20em"}
                    />
                )}
                
                {!collapsed && (
                    <span className="text-white text-base ml-2 font-light leading-6 align-bottom drop-shadow-md text-center text-ellipsis overflow-hidden ...">
                        {selectedGroup?.company?.plan_name}
                    </span>
                )}
            </div>

            {isPlansModalVisible && (
                <UserSubscriptionPlanModal
                    modalVisible={isPlansModalVisible}
                    setModalVisible={setIsPlansModalVisible}
                />
            )}
        </div>

        <div className=" ">
            <div className={`h-6 absolute right-4`}>
                <LanguageSwitcher color="light"/>
            </div>

           
        </div>

        <div className="flex flex-col items-center">
                <div className={`text-white text-center text-base align-middle font-bold w-full text-xs m-auto`}>
                    {!loading && user.first_name + " " + user.last_name}
                </div>

                <div className={`flex min-h-[42px] min-w-[200px] max-w-[70%] my-5`}>
                    <SelectGroup/> 
                </div>
            </div>

        <Menu
            items={menuItems}
            openKeys={openKeys}
            onOpenChange={handleOpenKeysChange}
            collapsed={collapsed}
            currentActiveMenuKey={activeMenuKey}
            toggleMobileMenu={toggleMobileMenu}
        />

        {!collapsed && (
            <div className="hidden md:block absolute bottom-10 right-0 px-7 w-[inherit] flex flex-col justify-content-center ">
                <AdminLink label="Espace Admin" type="button"/>
            </div>
        )}
        </div>
    );
};
