import React from "react";
import CrmCard from "./crmCard/CrmCard";
import BuildingPage from "ck_commun/src/app/modUtils/components/errorPage/BuildingPage";
export default function CrmDashboard() {
  return (
    <>
      <BuildingPage />
    </>
  );
}
