import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "../../../modUtils/components/componentsLibrary/componentsLibrary";
import { useDispatch } from "react-redux";
import { get_weather_by_site_list } from "../../slices/weatherSlice";
import { setMomentLocale } from "../../../modUtils/functions/dateFormat";
import moment from "moment";

function SiteWeatherModal({
  isVisible,
  setIsVisible,
  site,
  forecastDayNumber,
}) {
  const dispatch = useDispatch();
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(false);
  const forecastDayNb = forecastDayNumber || 5;
  const siteId = site?.id;
  const [errorMsg, setErrorMsg] = useState("Données météo non disponibles");
  setMomentLocale();
  const getDaysWeather = async () => {
    setLoading(true);
    setWeather(null);
    try {
      let result = await dispatch(
        get_weather_by_site_list({ siteId, forecastDayNb })
      ).unwrap();
      if (result) {
        setWeather(result);
      }
    } catch (error) {
      setErrorMsg(error?.data?.detail?.detail);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (siteId) {
      getDaysWeather();
    }
  }, [siteId]);
  function dateMeteo(timestamp) {
    if (timestamp === null) return "";
    else {
      const d = parseInt(timestamp);
      return moment.unix(d).format("ddd DD/MM");
    }
  }
  return (
    <Modal
      title={`Prévisions météo ${forecastDayNb} jours`}
      isOpen={isVisible}
      onClose={() => setIsVisible(false)}
    >
      <div className="font-bold text-center text-neutral-color-400 text-md mb-2">
        {site.name}{" "}
      </div>
      {siteId && site?.location_address !== null && (
        <div className="font-semibold text-center text-md mb-2 text-secondary-color-300">
          {`${site?.location_address?.city}${
            site?.location_address?.zipcode
              ? ` - ${site?.location_address?.zipcode}`
              : ""
          }`}
        </div>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <div className="mt-9">
          {siteId && weather ? (
            <>
              {weather?.map((weather, index) => (
                <React.Fragment key={index}>
                  <div
                    id={`weather-row-${index}`}
                    className={`p-2 items-center grid grid-cols-6 ${
                      index % 2 === 0 ? "bg-neutral-100" : ""
                    } ${index !== 0 ? "border-t border-green-500" : ""}`}
                  >
                    <div
                      className="text-left font-normal"
                      style={{ width: "30%" }}
                    >
                      {dateMeteo(weather?.day_date)}
                    </div>
                    <div className="col-span-3 flex flex-col md:flex-row items-center justfity-center text-md font-semibold">
                      <img
                        width={40}
                        src={weather?.day_image}
                        alt={weather?.day_weather_label}
                      />
                      <div className="w-4/5 text-center md:text-left ml-0 md:ml-9 truncate">
                        {weather?.day_weather_label}
                      </div>
                    </div>
                    <div className="text-center font-bold text-primary-color">
                      {weather?.day_mintemp}°C{" "}
                      <span className="text-xs font-normal">min</span>
                    </div>
                    <div className="text-center font-bold text-error-color">
                      {weather?.day_maxtemp}°C{" "}
                      <span className="text-xs font-normal">max</span>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </>
          ) : (
            <div className="font-semibold text-xs px-4">{errorMsg}</div>
          )}
        </div>
      )}
    </Modal>
  );
}

export default SiteWeatherModal;
