import React from "react";
import LoginPage from "../../cklibs/ck_commun/src/app/modAuthentication/pages/LoginPage";
import { appLogo, appShortName, contactPhoneNumber } from "../../utils/constants";

export default function Login() {


    return (
        <>
        <LoginPage
            appName={appShortName}
            contactPhoneNumber={contactPhoneNumber}
            appLogo={appLogo}
            displayFooter={false}
        />
        </>
    );
};
