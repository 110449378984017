import { useEffect, useRef } from "react";

export default function useScrollHandler(onScrollEnd) {
  const tableRef = useRef();

  const handleScroll = () => {
    const tableElement = tableRef.current;
    if (
      tableElement.scrollTop + tableElement.clientHeight >=
      tableElement.scrollHeight - 10
    ) {
      onScrollEnd();
    }
  };

  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableElement) {
        tableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return { tableRef, handleScroll };
}
