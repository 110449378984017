import React from "react";
import { Route } from "react-router-dom";
import PromoDashboard from "../pages/promo/dashboard/PromoDashboard";
import Promotions from "../pages/promo/promotions/Promotions";
import Voucher from "../pages/promo/voucher/Voucher";
import CkRoutes from "ck_commun/src/app/modUtils/routes/CkRoutes";

export default function PromoRoutes() {
  return (
    <CkRoutes>
      <Route path="dashboard" element={<PromoDashboard />} />
      <Route path="promotions" element={<Promotions />} />
      <Route path="voucher" element={<Voucher />} />
    </CkRoutes>
  );
}
